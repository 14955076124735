import React, { useState } from "react"
import styled from "styled-components"
import moment from "moment"

import Button from "../pages/settings/components/_Button"
import imgIcon from "../imgs/trash.png"

const DeleteButton = styled(Button)`
  @media screen and (max-width: 960px) {
    margin-left: 10px;
    padding: 12px 14px;
    .delete-text {
      display: none;
    }
  }
  .bin-icon {
    width: 24px;
  }

  @media screen and (min-width: 961px) {
    .bin-icon {
      display: none;
    }
  }
`

export default function ProxyListCard({ handleDelete, ...props }) {
  const type = props.feature ? "feature" : "productCategory"
  const descriptions = (props.feature
    ? props.feature.servicesOffered === "."
      ? props.feature.shortDescription
      : props.feature.servicesOffered
    : props[type].description
  ).split(",")

  const dateAdded = moment(props?.createdAt).format("M/D/YYYY")
  const title = props.feature ? props[type].title : props[type].name
  const [deleting, setDeleting] = useState(false)
  const deleteWaitlist = async () => {
    setDeleting(true)
    await handleDelete()
    setDeleting(false)
  }

  return (
    <tr>
      <td>
        {title}
        <br />
        <span className="small">{descriptions[0]}</span>
        <br />
        <span className="small">{descriptions[1]}</span>
      </td>
      <td>{dateAdded}</td>
      <td align="right">
        <DeleteButton onClick={deleteWaitlist} disabled={deleting}>
          <span className="delete-text">Delete</span>
          <img src={imgIcon} className="bin-icon" alt="Delete" />
        </DeleteButton>
      </td>
    </tr>
  )
}
